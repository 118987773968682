var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-drawer"},[_c('div',{class:[
            {
                'c-drawer__container': true,
                'dashboard-filter-settings': !!_vm.dashboardViewId,
                'dashboard-filter-settings--desktop-sub-navigation-closed':
                    _vm.desktopSubNavigationClosed,
            },
        ]},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"c-drawer__content"},[_c('div',{staticClass:"o-form"},[_c('legend',[_vm._v(_vm._s(_vm.$t("FILTER_SETTINGS_TITLE")))]),_c('ol',{staticClass:"o-form__list o-list-plain"},[_c('li',{staticClass:"o-form__item o-form__item--sm filter-settings-boxes"},[_c('el-transfer',{attrs:{"filterable":"","data":_vm.filterTemplates,"titles":[
                                _vm.$t('FILTER_SETTINGS_SELECTED_TITLE'),
                                _vm.$t('FILTER_SETTINGS_NOT_SELECTED_TITLE'),
                            ],"props":{
                                key: 'id',
                                label: 'name',
                            }},model:{value:(_vm.disallowedFilterTemplates),callback:function ($$v) {_vm.disallowedFilterTemplates=$$v},expression:"disallowedFilterTemplates"}})],1),_c('li',{staticClass:"o-form__item o-form__item--sm filter-settings-save-btn"},[_c('button',{staticClass:"c-btn c-btn--primary c-btn--block apply-filter-btn",attrs:{"disabled":!_vm.selectionChanged},on:{"click":_vm.saveFilterSettings}},[_vm._v(" "+_vm._s(_vm.$t("SAVE_FILTER_SETTINGS"))+" ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }