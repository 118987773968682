<template>
    <div>
        <el-dialog
            :before-close="popupClose"
            :title="$t('ADD_WIDGET_MODAL_TITLE')"
            :visible.sync="visible"
            class="widget-selection-popup"
            width="75%"
            v-on:open="popupOpened"
        >
            <el-card class="table-action-bar">
                <i class="el-icon-search"></i>
                <el-input
                    v-model="searchText"
                    :placeholder="$t('SEARCH_IN_LIBRARY')"
                    class="table-search-field"
                    @input="handleSearch"
                />
            </el-card>

            <el-table
                :data="displayedList"
                class="widget-selection-table"
                @sort-change="sortChange"
            >
                <el-table-column width="50">
                    <template slot-scope="scope">
                        <div v-if="scope.row.benchmark" class="widget-template-type benchmark">
                            B
                        </div>
                        <div v-else class="widget-template-type graph">G</div>
                    </template>
                </el-table-column>
                <el-table-column
                    :label="$t('WIDGET_TITLE')"
                    :min-width="300"
                    prop="defaultTitle"
                    sortable="custom"
                />
                <el-table-column
                    :label="$t('ACTIONS')"
                    :resizable="false"
                    :width="300"
                    align="center"
                >
                    <template slot-scope="scope">
                        <div class="table-column-right">
                            <el-button
                                v-for="button in actionsColDef(scope.row)"
                                :key="button.name"
                                :icon="button.icon"
                                :type="button.type"
                                class="widget-wizard-action-btn"
                                plain
                                size="small"
                                @click="button.handler"
                                >{{ $t(button.label) }}
                            </el-button>
                        </div>
                    </template>
                </el-table-column>
                <template slot="append">
                    <infinite-loading
                        ref="infiniteLoading"
                        :identifier="infiniteId"
                        @infinite="infiniteHandler"
                    ></infinite-loading>
                </template>
            </el-table>

            <el-card class="table-footer">
                <span>{{
                    $t("TABLE_INFO_SIZE", {
                        filteredListSize: displayedList.length,
                        originalListSize: filteredList.length,
                    })
                }}</span>
            </el-card>

            <span slot="footer">
                <el-button type="danger" @click="popupClose">{{ $t("CLOSE") }}</el-button>
            </span>
        </el-dialog>
        <widget-popup
            :fullScreen="false"
            :preview="true"
            :visible="widgetPreviewVisible"
            :widget="widgetForPreview"
            @close="closeWidgetPopup"
        ></widget-popup>
    </div>
</template>

<script>
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import { widgetService } from "@/services/Evaluation";
import WidgetPopup from "./widget/WidgetPopup.vue";

export default {
    name: "WidgetSelection",
    props: ["visible"],
    components: {
        WidgetPopup,
    },
    data() {
        return {
            searchText: "",
            sortOrder: null,

            pageSize: 25,

            originalList: [],
            filteredList: [],
            displayedList: [],

            infiniteId: +new Date(),

            widgetPreviewVisible: false,
            widgetForPreview: null,

            actionsColDef(widgetTemplate) {
                return [
                    {
                        name: "actionSelectWidgetTemplate",
                        icon: "el-icon-view",
                        type: "primary",
                        label: "ADD_TO_DASHBOARD",
                        handler: () => this.selectWidget(widgetTemplate),
                    },
                    {
                        name: "actionPreviewWidgetTemplate",
                        icon: "el-icon-view",
                        type: "primary",
                        label: "PREVIEW",
                        handler: () => this.openWidgetPopup(widgetTemplate),
                    },
                ];
            },
        };
    },
    methods: {
        popupOpened() {
            this.resetData();
            this.resetInfiniteLoading();
        },
        popupClose() {
            this.$emit("widgetSelectionClose");
            this.resetData();
        },
        getWidgetTemplates(infiniteLoadingState) {
            dashboardApiClient
                .get("/widget-templates")
                .then((response) => {
                    this.originalList = response.data;
                    this.setFilteredList();
                    this.displayedList = this.filteredList.slice(0, this.pageSize);
                    this.widgetTemplatesLoaded = true;
                    infiniteLoadingState.loaded();
                })
                .catch(() => {
                    this.originalList = [];
                    infiniteLoadingState.complete();
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_GETTING_WIDGET_TEMPLATES"),
                        "error"
                    );
                });
        },
        setFilteredList() {
            this.filteredList = this.originalList.filter(this.checkSearchCriteria);
            if (this.sortOrder != null) {
                this.filteredList.sort(
                    this.objectSortFunc(this.getSortProperty("defaultTitle", this.sortOrder))
                );
            }
        },
        handleSearch() {
            if (this.originalList.length > 0) {
                this.setFilteredList();
                this.resetInfiniteLoading();
            }
        },
        checkSearchCriteria(widgetTemplate) {
            // check search text
            if (this.searchText == null || this.searchText.trim() === "") {
                return true;
            }
            return (
                widgetTemplate.defaultTitle.toLowerCase().indexOf(this.searchText.toLowerCase()) >
                -1
            );
        },
        sortChange(sort) {
            this.sortOrder = sort.order;
            this.handleSearch();
        },
        infiniteHandler($state) {
            if (!this.widgetTemplatesLoaded) {
                this.getWidgetTemplates($state);
            } else {
                if (this.displayedList.length === this.filteredList.length) {
                    $state.complete();
                    return;
                }

                let toIndex = this.displayedList.length + this.pageSize;
                if (toIndex > this.filteredList.length) {
                    toIndex = this.filteredList.length;
                }
                this.displayedList = this.filteredList.slice(0, toIndex);

                if (this.displayedList.length < this.filteredList.length) {
                    $state.loaded();
                } else {
                    $state.complete();
                }
            }
        },
        resetInfiniteLoading() {
            this.displayedList = [];
            this.infiniteId += 1;
        },
        selectWidget(widgetTemplate) {
            this.$emit("widgetSelected", widgetTemplate);
            this.popupClose();
        },
        openWidgetPopup(widgetTemplate) {
            widgetService
                .getWidgetTemplate(widgetTemplate.id)
                .then((response) => {
                    this.widgetForPreview = {
                        widgetTemplate: response.data,
                        title: response.data.defaultTitle,
                    };
                    this.widgetPreviewVisible = true;
                })
                .catch((error) => {
                    /* eslint-disable no-console */
                    console.log(error);
                });
        },
        closeWidgetPopup() {
            this.widgetPreviewVisible = false;
            this.widgetForPreview = null;
        },
        resetData() {
            this.searchText = "";
            this.sortOrder = null;
            this.originalList = [];
            this.filteredList = [];
            this.displayedList = [];
            this.widgetTemplatesLoaded = false;
        },
    },
};
</script>
