var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showWidget)?_c('div',{class:[
        'c-chart',
        'c-box',
        'text-widget',
        'draggable-widget',
        _vm.loading ? 'is-loading' : '',
    ]},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.editMode && (_vm.showExpandableBox || _vm.isExpandableLeft || _vm.isExpandableRight)),expression:"editMode && (showExpandableBox || isExpandableLeft || isExpandableRight)"}],staticClass:"widget-expand-menu clearfix"},[_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpandableLeft),expression:"isExpandableLeft"}],on:{"click":function($event){return _vm.expandWidget(-1)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("arrow_back")])]),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.isExpandableRight),expression:"isExpandableRight"}],staticClass:"expand_right",on:{"click":function($event){return _vm.expandWidget(1)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("arrow_forward")])])]),(_vm.editMode)?_c('div',{staticClass:"c-chart__header c-drawer"},[_c('div',{staticClass:"c-chart__actions"},[_c('el-tooltip',{attrs:{"openDelay":500,"content":_vm.$t('EDIT'),"effect":"dark","placement":"bottom"}},[_c('button',{staticClass:"c-btn c-btn--clean c-btn--gray",on:{"click":_vm.editWidget}},[_c('i',{staticClass:"material-icons"},[_vm._v("edit")])])]),_c('el-tooltip',{attrs:{"openDelay":500,"content":_vm.$t('REMOVE'),"effect":"dark","placement":"bottom"}},[(_vm.canBeRemoved)?_c('button',{staticClass:"c-btn c-btn--clean c-btn--gray text-widget-remove-btn",on:{"click":_vm.removeWidget}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])]):_vm._e()])],1)]):_vm._e(),_c('div',{staticClass:"c-chart__content"},[(_vm.widget != null)?_c('div',{staticClass:"text-widget-content",domProps:{"innerHTML":_vm._s(_vm.widget.text)}}):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"c-loader"})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }