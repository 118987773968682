<template>
    <div class="date-filter">
        <legend>{{ $t("DASHBOARD_APP_DATE_FILTER") }}</legend>
        <ol class="o-form__list o-list-plain">
            <li class="o-form__item o-form__item--sm">
                <div class="o-form__select o-form__select--full">
                    <select v-model="filterType" @change="filterTypeChanged">
                        <option
                            v-for="(dateFilterType, i) in dateFilterTypes"
                            :key="i"
                            :value="dateFilterType"
                        >
                            {{ $t(dateFilterType) }}
                        </option>
                    </select>
                </div>
            </li>
            <li class="o-form__item o-form__item--sm">
                <el-date-picker
                    v-model="startDate"
                    :editable="false"
                    :format="dateDisplayFormat"
                    :picker-options="startDatePickerOptions"
                    :placeholder="$t('START_DATE')"
                    :value-format="dateValueFormat"
                    class="filter-start-date"
                    type="date"
                    v-on:change="saveFilter"
                >
                </el-date-picker>
            </li>
            <li class="o-form__item o-form__item--sm">
                <el-date-picker
                    v-model="endDate"
                    :editable="false"
                    :format="dateDisplayFormat"
                    :picker-options="endDatePickerOptions"
                    :placeholder="$t('END_DATE')"
                    :value-format="dateValueFormat"
                    class="filter-end-date"
                    type="date"
                    v-on:change="saveFilter"
                >
                </el-date-picker>
            </li>
            <li class="o-form__item o-form__item--sm">
                <div class="c-dropdown time-period">
                    <button
                        v-click-outside="closeDropdown"
                        class="c-btn c-btn--clean c-btn--gray"
                        @click="toggleDropdown"
                    >
                        <i class="material-icons">access_time</i>
                    </button>
                    <div
                        class="c-dropdown__container c-dropdown__container--arrow time-period-dropdown"
                    >
                        <span class="c-dropdown__arrow"><span></span><span></span></span>
                        <div class="c-dropdown__content">
                            {{ $t("DASHBOARD_APP_TIME_PERIOD") }}
                        </div>
                        <ul class="c-dropdown__list o-list-plain">
                            <li>
                                <button class="c-btn c-btn--block" @click="lastWeek">
                                    {{ $t("LAST_WEEK") }}
                                </button>
                            </li>
                            <li>
                                <button class="c-btn c-btn--block" @click="lastMonth">
                                    {{ $t("LAST_MONTH") }}
                                </button>
                            </li>
                            <li>
                                <button class="c-btn c-btn--block" @click="lastYear">
                                    {{ $t("LAST_YEAR") }}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </li>
        </ol>
    </div>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import util from "@/helpers/evaluation/util";
import { filterService } from "@/services/Evaluation";

export default {
    name: "DateFilter",
    mixins: [util, validationMixin],
    props: ["dashboardViewId", "appliedFilters"],
    data() {
        return {
            currentFilter: null,

            filterType: "SEND_DATE",
            startDate: "",
            endDate: "",

            dateDisplayFormat: "dd/MM/yyyy",
            dateValueFormat: "MM/dd/yyyy",
            momentDateValueFormat: "MM/DD/YYYY",
            momentDateBackendFormat: "YYYY-MM-DD",

            startDatePickerOptions: {
                disabledDate: this.startDateDisabled,
            },
            endDatePickerOptions: {
                disabledDate: this.endDateDisabled,
            },
        };
    },
    computed: {
        activeDashboard() {
            return this.$store.state.DashboardStore.activeDashboard;
        },
        dateFilterTypes() {
            if (this.activeDashboard.communicationsDashboard) {
                return ["SEND_DATE"];
            }
            return ["REACTION_DATE", "SEND_DATE"];
        },
    },
    mounted() {
        this.initFilter();
    },
    methods: {
        initFilter() {
            this.resetFilter();

            // find date filter
            $.each(this.appliedFilters, (index, filter) => {
                if (this.dateFilterTypes.indexOf(filter.filterTemplate.name) !== -1) {
                    this.currentFilter = filter;
                    this.filterType = filter.filterTemplate.name;

                    if (filter.filterValues[0].value != null) {
                        const startDate = moment(
                            filter.filterValues[0].value,
                            this.momentDateBackendFormat
                        ).format(this.momentDateValueFormat);
                        this.startDate = startDate !== "Invalid date" ? startDate : "";
                    } else {
                        this.startDate = "";
                    }

                    if (filter.filterValues[1].value != null) {
                        const endDate = moment(
                            filter.filterValues[1].value,
                            this.momentDateBackendFormat
                        ).format(this.momentDateValueFormat);
                        this.endDate = endDate !== "Invalid date" ? endDate : "";
                    } else {
                        this.endDate = "";
                    }

                    return false;
                }
            });
        },
        resetFilter() {
            this.currentFilter = null;
            // eslint-disable-next-line prefer-destructuring
            this.filterType = this.dateFilterTypes[0];
            this.startDate = "";
            this.endDate = "";
        },
        filterTypeChanged() {
            if (
                (this.startDate != null && this.startDate !== "") ||
                (this.endDate != null && this.endDate !== "")
            ) {
                this.saveFilter();
            }
        },
        saveFilter() {
            if (
                (this.startDate != null && this.startDate !== "") ||
                (this.endDate != null && this.endDate !== "")
            ) {
                const filter = {};
                filter.dateFilter = true;
                filter.dashboardViewId = this.dashboardViewId;
                filter.reaction = this.filterType === "REACTION_DATE";
                filter.filterValues = [this.startDate, this.endDate];
                filterService.save(filter).then(() => {
                    this.$emit("reload");
                });
            } else if (this.currentFilter != null) {
                filterService.delete(this.currentFilter.id).then(() => {
                    this.resetFilter();
                    this.$emit("reload");
                });
            }
        },
        lastWeek() {
            const from = moment()
                .subtract(1, "week")
                .startOf("isoWeek")
                .format(this.momentDateValueFormat);
            const till = moment()
                .subtract(1, "week")
                .endOf("isoWeek")
                .format(this.momentDateValueFormat);
            this.setRange(from, till);
        },
        lastMonth() {
            const from = moment()
                .subtract(1, "month")
                .startOf("month")
                .format(this.momentDateValueFormat);
            const till = moment()
                .subtract(1, "month")
                .endOf("month")
                .format(this.momentDateValueFormat);
            this.setRange(from, till);
        },
        lastYear() {
            const from = moment()
                .subtract(1, "year")
                .startOf("month")
                .format(this.momentDateValueFormat);
            const till = moment().format(this.momentDateValueFormat);
            this.setRange(from, till);
        },
        setRange(from, till) {
            this.startDate = from;
            this.endDate = till;
            this.saveFilter();
        },
        startDateDisabled(time) {
            let endDate = null;
            if (this.endDate != null && this.endDate !== "") {
                endDate = moment(this.endDate, this.momentDateValueFormat);
            }
            if (endDate != null && endDate.isValid()) {
                return time.getTime() > endDate.valueOf();
            }
            return false;
        },
        endDateDisabled(time) {
            let startDate = null;
            if (this.startDate != null && this.startDate !== "") {
                startDate = moment(this.startDate, this.momentDateValueFormat);
            }
            if (startDate != null && startDate.isValid()) {
                return time.getTime() < startDate.valueOf();
            }
            return false;
        },
    },
    watch: {
        appliedFilters() {
            this.initFilter();
        },
    },
};
</script>
