<template>
    <div class="c-drawer">
        <div class="c-drawer__container">
            <div class="c-drawer__content c-drawer__content--split-by">
                <div class="o-form">
                    <legend>{{ $t("ANONYMIZE") }}</legend>
                    <ol class="o-form__list o-list-plain">
                        <li class="o-form__item o-form__item--sm">
                            <div class="o-form__select o-form__select--full">
                                <select v-model="filterTemplate" @change="templateSelected">
                                    <option :value="null" disabled>
                                        {{ filterTemplatesLabel }}
                                    </option>
                                    <option
                                        v-for="(template, index) in filterTemplates"
                                        :key="index"
                                        v-show="template.values && template.values.length > 0"
                                        :value="template"
                                    >
                                        {{ template.name }}
                                    </option>
                                </select>
                            </div>
                        </li>
                        <li class="o-form__item o-form__item--sm">
                            <div class="c-dropdown">
                                <Dropdown
                                    ref="filterValueDropdown"
                                    style="width: 100%"
                                    compact
                                    v-model="selectedFilterTemplateValue"
                                    placeholder="Select a value"
                                    :searchAble="filteredTemplateValues.length > 8"
                                    :options="filteredTemplateValues"
                                    :visibleItems="8"
                                />
                            </div>
                        </li>
                        <li
                            v-if="role === 'ROLE_ADMIN' || role === 'ROLE_SUPERADMIN'"
                            class="o-form__item"
                        >
                            <div class="c-checkbox">
                                <input
                                    :id="'widget-' + widget.id + '-anonymize-irremovable'"
                                    v-model="irremovable"
                                    type="checkbox"
                                />
                                <label
                                    :for="'widget-' + widget.id + '-anonymize-irremovable'"
                                    class="o-form__label o-form__label--checkbox"
                                    >{{ $t("IRREMOVABLE") }}</label
                                >
                            </div>
                        </li>
                        <li class="o-form__item o-form__item--sm">
                            <button
                                :disabled="!this.anonymization.id"
                                class="c-btn c-btn--outline c-btn--block"
                                @click="removeAnonymization"
                            >
                                {{ $t("CLEAR") }}
                            </button>
                        </li>
                        <li class="o-form__item o-form__item--sm">
                            <button
                                :disabled="!selectedFilterTemplateValue"
                                class="c-btn c-btn--primary c-btn--block"
                                @click="createAnonymization"
                            >
                                {{ $t("APPLY") }}
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { anonymizationService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";
import { Dropdown } from "@feedbackcompany/feedback-company-vue-components";

export default {
    name: "Anonymize",
    components: { Dropdown },
    mixins: [util, validationMixin],
    props: ["visible", "widget"],
    data() {
        return {
            filterTemplate: null,
            templateValues: [],
            searchText: "",
            allSelected: false,
            irremovable: false,
            selectedFilterTemplateValue: null,
        };
    },
    computed: {
        role() {
            return "ROLE_SUPERADMIN";
        },
        anonymization() {
            return this.widget.anonymization ? this.widget.anonymization : {};
        },
        activeDashboard() {
            return this.$store.state.DashboardStore.activeDashboard;
        },
        filterTemplates() {
            return this.$store.state.DashboardStore.filterTemplates.filter(
                (filterTemplate) =>
                    // Special case: communications dashboard and recipients table
                    // eslint-disable-next-line implicit-arrow-linebreak
                    !(
                        this.activeDashboard.communicationsDashboard &&
                        filterTemplate.table === "dimRecipient"
                    ) && this.widget?.splitBys[0]?.filterTemplate.id === filterTemplate.id
            );
        },
        filterTemplatesLoaded() {
            return this.$store.state.DashboardStore.filterTemplatesLoaded;
        },
        filterTemplatesLabel() {
            if (this.filterTemplatesLoaded) {
                return this.translate("CHOOSE_FILTER");
            }
            return this.translate("DASHBOARD_APP_LOADING_FILTER_TEMPLATES");
        },
        filteredTemplateValues() {
            return this.templateValues.filter((templateValue) => {
                if (this.searchText == null || this.searchText.trim() === "") {
                    return true;
                }
                return (
                    templateValue.value
                        .toString()
                        .toLowerCase()
                        .indexOf(this.searchText.toLowerCase()) > -1
                );
            });
        },
    },
    mounted() {
        if (this.filterTemplatesLoaded) {
            this.init();
        }
    },
    methods: {
        init() {
            if (this.anonymization.id) {
                $.each(this.filterTemplates, (index, template) => {
                    if (template.id === this.anonymization.filterTemplate.id) {
                        this.filterTemplate = template;
                        return false;
                    }
                });
                this.selectedFilterTemplateValue = this.anonymization.value;
                this.irremovable = this.anonymization.irremovable;
                this.populateTemplateValues();
            } else {
                this.resetForm();
            }
        },
        templateSelected() {
            this.populateTemplateValues([]);
            this.searchText = "";
        },
        populateTemplateValues() {
            const values = [];
            if (this.filterTemplate != null) {
                $.each(this.filterTemplate.values, (index, value) => {
                    values.push({
                        index,
                        value: value[0].toString(),
                        displayValue: value[0].toString(),
                    });
                });
                values.sort(this.objectSortFunc("value"));
            }
            this.templateValues = values;
        },
        toggleAll() {
            $.each(this.templateValues, (index, templateValue) => {
                templateValue.selected = this.allSelected;
            });
        },
        removeAnonymization() {
            if (this.anonymization.id) {
                anonymizationService.delete(this.widget.id, this.anonymization.id).then(() => {
                    this.$emit("reloadWidget");
                });
            }
        },
        createAnonymization() {
            if (this.selectedFilterTemplateValue) {
                const newAnonymization = {};
                if (this.anonymization.id) {
                    newAnonymization.id = this.anonymization.id;
                }
                newAnonymization.filterTemplate = this.filterTemplate;
                newAnonymization.irremovable = this.irremovable;
                newAnonymization.value = this.selectedFilterTemplateValue;
                anonymizationService.save(this.widget.id, newAnonymization).then(() => {
                    this.$emit("close");
                    this.$emit("reloadWidget");
                });
            }
        },
        resetForm() {
            this.filterTemplate = null;
            this.templateValues = [];
            this.searchText = "";
            this.selectedFilterTemplateValue = null;
            this.irremovable = false;
        },
        closeFilterValueDropdown(event) {
            this.closeDropdown(event, this.$refs.filterValueDropdown);
        },
    },
    watch: {
        widget() {
            if (!this.anonymization.id) {
                this.resetForm();
            }
        },
        filterTemplatesLoaded(loaded) {
            if (loaded) {
                this.init();
            }
        },
        visible() {
            if (this.visible) {
                this.init();
            }
        },
    },
};
</script>
