<template>
    <LoaderCircular v-if="loading" class="loader-circular" />
    <div v-else>
        <SubNavigation v-if="showSubNavigation" @subNavigationToggle="onSubNavigationToggle">
            <template v-slot:content>
                <DashboardsList />
            </template>
            <DashboardsModule ref="dashboardsModule" :mode="mode">
                <Dashboard />
            </DashboardsModule>
        </SubNavigation>
        <div v-if="!showSubNavigation">
            <DashboardsModule :mode="mode">
                <Dashboard />
            </DashboardsModule>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, onBeforeUnmount } from "vue";
import { useStore, useRoute } from "@/helpers/composition-helper";
import { LoaderCircular, SubNavigation } from "@feedbackcompany/feedback-company-vue-components";
import DashboardsModule from "@/components/Evaluation/DashboardsModule.vue";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import Dashboard from "@/components/Evaluation/Dashboards/views/Dashboard.vue";
import clearDashboardsEventListeners from "@/helpers/evaluation/clearDashboardsEventListeners";
import DashboardsList from "@/components/Evaluation/Dashboards/DashboardsList.vue";
import { EventBus } from "@/helpers/evaluation/event-bus";

const store = useStore();
const route = useRoute();

const loading = ref(true);
const mode = ref(null);

const editMode = computed(() => {
    return store.state.DashboardStore.dashboardEditMode;
});

const showSubNavigation = computed(() => {
    return !editMode.value && mode.value !== "Communications";
});

function onSubNavigationToggle() {
    EventBus.$emit("redrawAllCharts");
}

function setMode(dashboardType) {
    if (dashboardType === "DASHBOARD") {
        mode.value = "My Dashboards";
    }
    if (dashboardType === "RESPONSE_OVERVIEW") {
        mode.value = "Communications";
    }
    if (dashboardType === "RESULTS") {
        mode.value = "Results";
    }
}

onMounted(async () => {
    window.localStorage.removeItem("evDashboards-dashboard");
    window.localStorage.removeItem("evDashboards-selectedView");
    const response = await dashboardApiClient.get(`/dashboard-views/${route.params.id}/owner`);
    const authHeaderValues = response.data;
    window.localStorage.setItem("evAuthHeaderValues", JSON.stringify(authHeaderValues));

    const dashboardType = route.query.type;
    setMode(dashboardType);

    await nextTick();
    loading.value = false;
});

onBeforeUnmount(async () => {
    clearDashboardsEventListeners();
});

defineExpose({
    setMode,
});
</script>

<script>
export default {
    beforeRouteUpdate(to, from, next) {
        this.setMode(to.query.type);
        // reloading dashboard state on route change
        this.$refs.dashboardsModule.getDashboards();
        next();
    },
    beforeRouteLeave(to, from, next) {
        // This workaround excludes Dashboards specific stylesheets from other pages
        // by refreshing the page entirely when navigating to another page.
        if (["WidgetEditorBridge", "DashboardsBridge", "DashboardsEditBridge"].includes(to.name)) {
            next();
        } else {
            window.location = to.fullPath;
        }
    },
};
</script>

<style lang="scss" scoped>
.loader-circular {
    margin: 0 auto;
}
</style>
