<template>
    <el-dialog
        :visible.sync="visible"
        :before-close="popupClose"
        class="custom-dialog delete-all-filters"
        :show-close="false"
    >
        <div class="c-modal c-modal--with-tabs" v-loading="loading">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ $t("CLEAR_ALL_FILTERS") }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click.stop="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <p>{{ $t("DASHBOARD_APP_DELETE_ALL_FILTERS_MSG", { number: numOfFilters }) }}</p>
                <div class="c-checkbox" v-if="dashboardViewId != null">
                    <input
                        type="checkbox"
                        id="delete-from-widgets"
                        name="delete-from-widgets"
                        v-model="deleteFromWidgets"
                    />
                    <label
                        class="o-form__label o-form__label--checkbox"
                        for="delete-from-widgets"
                        >{{ $t("REMOVE_ALL_FILTERS_FROM_WIDGETS_ALSO") }}</label
                    >
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click.stop="popupClose">
                    {{ $t("CANCEL") }}
                </button>
                <button class="c-btn c-btn--primary" @click.stop="deleteAllFilters">
                    {{ $t("DELETE") }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { validationMixin } from "vuelidate";
import { filterService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "DeleteFilters",
    mixins: [util, validationMixin],
    props: ["visible", "dashboardViewId", "widgetId", "numOfFilters"],
    data() {
        return {
            loading: false,
            deleteFromWidgets: false,
        };
    },
    methods: {
        popupClose() {
            this.$emit("close");
            this.deleteFromWidgets = false;
        },
        deleteAllFilters() {
            this.loading = true;
            filterService
                .deleteAll(this.dashboardViewId, this.widgetId, this.deleteFromWidgets)
                .then(() => {
                    this.loading = false;
                    this.$emit("deleted", this.widgetId);
                    this.popupClose();
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ALL_FILTERS_DELETED"),
                        "success"
                    );
                })
                .catch(() => {
                    this.loading = false;
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_DELETING_ALL_FILTERS"),
                        "error"
                    );
                });
        },
    },
};
</script>
