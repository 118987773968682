<template>
    <div :class="{ container: loading }">
        <div :class="{ overlay: loading }">
            <h4 class="title">Dashboards</h4>
            <SearchBar
                v-if="searchIsEnabled"
                class="search-bar"
                :value="searchQuery"
                @input="searchQuery = $event"
                placeholder="Search"
            />
            <draggable
                tag="ul"
                class="list"
                :list="dashboards"
                v-bind="dragOptions"
                @end="updateOrder"
            >
                <div v-for="(dashboard, key) in dashboards" :key="key" class="list-item">
                    <router-link
                        class="link"
                        :to="{
                            path: `/dashboards/${dashboard.id}`,
                            query: { company: $route.query.company, type: dashboard.type },
                        }"
                        v-line-clamp:32="1"
                    >
                        {{ dashboard.name }}
                    </router-link>
                    <IconButton v-if="!searchQuery" label="" class="handle">
                        <font-awesome-icon :icon="['fas', 'grip-dots-vertical']" />
                    </IconButton>
                </div>
            </draggable>
        </div>
        <LoaderCircular v-if="loading" class="loader-circular" />
    </div>
</template>

<script>
import draggable from "vuedraggable";
import {
    SearchBar,
    IconButton,
    LoaderCircular,
} from "@feedbackcompany/feedback-company-vue-components";
import { faGripDotsVertical } from "@fortawesome/pro-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { EventBus } from "@/helpers/evaluation/event-bus";
import { getDashboardsList } from "@/graphql/dashboards/getDashboardsList.gql";
import { reorderDashboards } from "@/graphql/dashboards/reorderDashboards.gql";
import cloneDeep from "lodash.clonedeep";

library.add(faGripDotsVertical);

export default {
    name: "DashboardsList",
    components: {
        SearchBar,
        draggable,
        FontAwesomeIcon,
        IconButton,
        LoaderCircular,
    },
    data() {
        return {
            searchQuery: "",
            availableDashboards: [],
            dragOptions: {
                handle: ".handle",
                ghostClass: "ghost",
                dragClass: "dragging-ticket",
            },
            loading: false,
        };
    },
    computed: {
        dashboards() {
            if (!this.searchIsEnabled) return this.availableDashboards;
            if (this.searchQuery === "") return this.availableDashboards;

            const normalise = (string) => string.trim().toLowerCase();
            return this.availableDashboards.filter((dashboard) =>
                normalise(dashboard.name).includes(normalise(this.searchQuery))
            );
        },
        searchIsEnabled() {
            return this.availableDashboards.length >= 10;
        },
    },
    methods: {
        onDashboardClick() {
            this.$store.commit("AccountStore/subNavigationTrayOpenPercentage", {
                openPercentage: 0,
            });
        },
        async updateOrder(event) {
            this.loading = true;
            try {
                const payload = {
                    mutation: reorderDashboards,
                    variables: {
                        input: {
                            dashboardViewId: this.dashboards[event.newIndex].id,
                            newPosition: event.newIndex,
                        },
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.reorderDashboards) {
                    this.loading = false;
                } else {
                    this.showError();
                }
            } catch (e) {
                this.showError();
            }
        },
        async showError() {
            this.loading = false;
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Something went wrong while moving a dashboard.",
            });
        },
        async getDashboards(cacheFirst) {
            this.loading = true;
            this.availableDashboards = [];
            let authHeaderValues = window.localStorage.getItem("evAuthHeaderValues");
            authHeaderValues = JSON.parse(authHeaderValues);
            this.loading = true;
            const { data } = await this.$apollo.query({
                query: getDashboardsList,
                context: {
                    headers: {
                        "X-Tevreden-Host": authHeaderValues.platform,
                        "X-Tevreden-Username": authHeaderValues.username,
                    },
                },
                fetchPolicy: cacheFirst ? "cache-first" : "network-only",
            });
            this.availableDashboards = cloneDeep(data.dashboardViews);
            this.loading = false;
        },
    },
    async created() {
        await this.getDashboards(true);
        EventBus.$on("reloadDashboardNavigation", this.getDashboards);
    },
};
</script>

<style lang="scss" scoped>
@import "~include-media";
@import "../../../style_variables/style_variables";

.title {
    font-family: "Montserrat", sans-serif;
    margin: 0 0 20px 0;
    font-size: 1rem;
    font-weight: 700;
}

.list {
    padding: 0;
    list-style-type: none;

    @include media($isDesktop...) {
        height: calc(100vh - 212px);
        overflow-y: auto;
    }

    .list-item {
        @extend %body2_style;
        padding: 0;
        line-height: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .link {
            text-decoration: none;
            user-select: none;
            max-width: 180px;

            &.router-link-active {
                color: $blue;
            }
        }
    }
}

.search-bar {
    width: 100%;
    margin-bottom: 16px;
}

.handle {
    float: right;
    &:hover {
        color: $grey_french;
    }
    &:active {
        cursor: grab;
        color: $grey_french;
    }
}

.ghost {
    * {
        visibility: hidden;
    }
    height: 1px;
    background: $blue;
}

.dragging-ticket {
    background-color: #ecf3fe;
    a {
        margin-left: 10px;
    }
}

.loader-circular {
    margin: 0 auto;
}

.container {
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
}

.overlay {
    width: 100%;
    height: 100%;
    opacity: 0.1;
    position: absolute;
}
</style>
