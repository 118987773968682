<template>
    <el-dialog
        :before-close="popupClose"
        :show-close="false"
        :visible.sync="visible"
        class="custom-dialog text-widget-edit"
        v-on:open="popupOpened"
    >
        <div v-loading="loading" class="c-modal c-modal--with-tabs">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ $t("TEXT_WIDGET_TITLE") }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div class="o-form" label-width="80px">
                    <fieldset>
                        <ul class="o-form__fields o-list-plain">
                            <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                <editor v-model="$v.widgetForm.text.$model" :init="tinyMCEConfig">
                                </editor>
                                <label class="o-form__label">{{ $t("TEXT_WIDGET_TEXT") }}</label>
                                <span
                                    v-if="$v.widgetForm.text.$dirty && !$v.widgetForm.text.required"
                                    class="error-msg"
                                    >{{ $t("TEXT_WIDGET_TEXT_REQUIRED") }}</span
                                >
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click="popupClose">{{ $t("CANCEL") }}</button>
                <button
                    :disabled="loading || $v.$invalid"
                    class="c-btn c-btn--primary"
                    @click="saveTextWidget"
                >
                    {{ $t("SAVE") }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
// eslint-disable-next-line
import tinymce from 'tinymce/tinymce';
import "tinymce/themes/modern/theme";
import "tinymce/plugins/lists";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/textcolor";

import "tinymce/skins/lightgray/skin.min.css";

import Editor from "@tinymce/tinymce-vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import util from "@/helpers/evaluation/util";

import { widgetService } from "@/services/Evaluation";

export default {
    name: "TextWidgetEdit",
    mixins: [util, validationMixin],
    props: ["visible", "widgetData"],
    components: {
        editor: Editor,
    },
    data() {
        return {
            loading: false,
            widgetForm: {
                text: "",
            },

            tinyMCEConfig: {
                plugins: ["lists", "code", "link", "image", "textcolor"],
                width: 750,
                height: 200,

                skin: false,
                branding: false,
                statusbar: false,
                menubar: false,

                toolbar:
                    "undo redo | styleselect | fontsizeselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor | link image | code",

                forced_root_block: false,
                entity_encoding: "raw",
            },
        };
    },
    validations: {
        widgetForm: {
            text: {
                required,
            },
        },
    },
    methods: {
        popupOpened() {
            if (this.widgetData) {
                this.widgetForm.text = this.widgetData.text;
            } else {
                this.widgetForm.text = "";
            }
        },
        popupClose() {
            this.$emit("close");
            this.resetFormData();
        },
        saveTextWidget() {
            if (!this.$v.$anyError) {
                if (!this.widgetData) {
                    this.$emit("add", this.widgetForm.text);
                    this.popupClose();
                } else if (this.widgetData.text !== this.widgetForm.text) {
                    this.loading = true;
                    const widget = {
                        id: this.widgetData.id,
                        text: this.widgetForm.text,
                    };
                    widgetService
                        .updateTextWidget(widget)
                        .then(() => {
                            this.loading = false;
                            this.$emit("update", {
                                widgetId: widget.id,
                                text: widget.text,
                            });
                            this.popupClose();
                        })
                        .catch(() => {
                            this.loading = false;
                            this.displayMessage(
                                this.translate("DASHBOARD_APP_UPDATE_TEXT_WIDGET_ERROR"),
                                "error"
                            );
                        });
                } else {
                    this.popupClose();
                }
            }
        },
        resetFormData() {
            this.widgetForm.text = "";
            this.$v.$reset();
        },
    },
};
</script>
