var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-drawer"},[_c('div',{class:[
            {
                'c-drawer__container': true,
                'dashboard-filter': !!_vm.dashboardViewId,
                'dashboard-filter--desktop-sub-navigation-closed': _vm.desktopSubNavigationClosed,
            },
        ]},[_c('div',{staticClass:"c-drawer__content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.appliedFilters.length > 0 || _vm.dashboardViewId),expression:"appliedFilters.length > 0 || dashboardViewId"}],staticClass:"c-drawer__filters"},[_c('applied-filters',{attrs:{"applied-filters":_vm.appliedFilters},on:{"reload":_vm.reload}}),_c('button',{staticClass:"c-btn c-btn--clean c-btn--gray",on:{"click":_vm.deleteFilters}},[_c('i',{staticClass:"material-icons"},[_vm._v("delete")])])],1),_c('div',{staticClass:"o-form"},[(_vm.showDateFilter)?_c('date-filter',{attrs:{"applied-filters":_vm.appliedFilters,"dashboard-view-id":_vm.dashboardViewId},on:{"reload":_vm.reload}}):_vm._e(),_c('template-value-filter',{ref:"valueFilter",attrs:{"dashboard-view-id":_vm.dashboardViewId,"visible":_vm.visible,"widget-id":_vm.widgetId},on:{"reload":_vm.reload,"applyFilter":_vm.applyFilter}})],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }