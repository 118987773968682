<template>
    <div class="c-drawer">
        <div
            :class="[
                {
                    'c-drawer__container': true,
                    'dashboard-filter-settings': !!dashboardViewId,
                    'dashboard-filter-settings--desktop-sub-navigation-closed':
                        desktopSubNavigationClosed,
                },
            ]"
        >
            <div class="c-drawer__content" v-loading="loading">
                <div class="o-form">
                    <legend>{{ $t("FILTER_SETTINGS_TITLE") }}</legend>
                    <ol class="o-form__list o-list-plain">
                        <li class="o-form__item o-form__item--sm filter-settings-boxes">
                            <el-transfer
                                filterable
                                v-model="disallowedFilterTemplates"
                                :data="filterTemplates"
                                :titles="[
                                    $t('FILTER_SETTINGS_SELECTED_TITLE'),
                                    $t('FILTER_SETTINGS_NOT_SELECTED_TITLE'),
                                ]"
                                :props="{
                                    key: 'id',
                                    label: 'name',
                                }"
                            >
                            </el-transfer>
                        </li>
                        <li class="o-form__item o-form__item--sm filter-settings-save-btn">
                            <button
                                :disabled="!selectionChanged"
                                class="c-btn c-btn--primary c-btn--block apply-filter-btn"
                                @click="saveFilterSettings"
                            >
                                {{ $t("SAVE_FILTER_SETTINGS") }}
                            </button>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as Sentry from "@sentry/vue";
import { validationMixin } from "vuelidate";
import { mapState } from "vuex";
import util from "@/helpers/evaluation/util";
import { getDashboardFilterSettings } from "@/graphql/dashboards/getDashboardFilterSettings.gql";
import { saveDashboardFilterSettings } from "@/graphql/dashboards/saveDashboardFilterSettings.gql";
import cloneDeep from "lodash.clonedeep";
import xor from "lodash.xor";

export default {
    name: "DashboardFilterSettingsComponent",
    mixins: [util, validationMixin],
    props: ["visible", "dashboardViewId"],
    data() {
        return {
            loading: false,
            filterTemplates: [],
            disallowedFilterTemplates: [],
            savedAllowedAll: false,
            savedAllowedFilterTemplates: [],
        };
    },
    computed: {
        ...mapState({
            desktopSubNavigationClosed: (state) => state.DashboardStore.desktopSubNavigationClosed,
        }),
        allowedFilterTemplates() {
            if (this.allowedAll) {
                return [];
            }
            return this.filterTemplates
                .filter((template) => !this.disallowedFilterTemplates.includes(template.id))
                .map((template) => template.id);
        },
        allowedAll() {
            return !this.disallowedFilterTemplates || this.disallowedFilterTemplates.length === 0;
        },
        selectionChanged() {
            return (
                this.allowedAll !== this.savedAllowedAll ||
                xor(this.allowedFilterTemplates, this.savedAllowedFilterTemplates).length > 0
            );
        },
    },
    methods: {
        init() {
            if (this.savedAllowedAll) {
                this.disallowedFilterTemplates = [];
            } else if (
                this.savedAllowedFilterTemplates &&
                this.savedAllowedFilterTemplates.length > 0
            ) {
                this.disallowedFilterTemplates = this.filterTemplates
                    .filter((template) => !this.savedAllowedFilterTemplates.includes(template.id))
                    .map((template) => template.id);
            } else {
                this.disallowedFilterTemplates = this.filterTemplates.map(
                    (template) => template.id
                );
            }
        },
        async getFilterSettings() {
            this.loading = true;
            const { data } = await this.$apollo.query({
                query: getDashboardFilterSettings,
                variables: { id: this.dashboardViewId },
                fetchPolicy: "network-only",
            });
            this.filterTemplates = cloneDeep(data.getDashboardFilterSettings.filterTemplates).sort(
                (template1, template2) =>
                    template1.name.localeCompare(template2.name, undefined, { sensitivity: "base" })
            );
            this.savedAllowedAll = data.getDashboardFilterSettings.allowedAll;
            this.savedAllowedFilterTemplates = data.getDashboardFilterSettings
                .allowedFilterTemplates
                ? data.getDashboardFilterSettings.allowedFilterTemplates
                : [];
            this.init();
            this.loading = false;
        },
        async saveFilterSettings() {
            this.loading = true;
            try {
                const payload = {
                    mutation: saveDashboardFilterSettings,
                    variables: {
                        input: {
                            dashboardViewId: this.dashboardViewId,
                            allowedFilterTemplates: this.allowedFilterTemplates,
                            allowedAll: this.allowedAll,
                        },
                    },
                };

                const { data } = await this.$apollo.mutate(payload);
                if (data.saveDashboardFilterSettings) {
                    this.savedAllowedAll = this.allowedAll;
                    this.savedAllowedFilterTemplates = this.allowedFilterTemplates;
                    await this.$store.dispatch("pushNotification", {
                        type: "success",
                        title: "Applied",
                        message: "Successfully applied selected filter templates.",
                    });
                    this.changed();
                } else {
                    await this.pushError();
                }
            } catch (error) {
                Sentry.captureException(error);
                await this.pushError();
            }
            this.loading = false;
        },
        async pushError() {
            await this.$store.dispatch("pushNotification", {
                type: "error",
                title: "Error",
                message: "Something went wrong while applying selected filter templates.",
            });
        },
        changed() {
            this.$emit("changed", {
                allowedAll: this.savedAllowedAll,
                allowedFilterTemplates: this.savedAllowedFilterTemplates,
            });
        },
    },
    watch: {
        visible() {
            if (this.visible) {
                this.init();
            }
        },
    },
    async created() {
        await this.getFilterSettings();
    },
};
</script>

<style lang="scss" scoped>
.dashboard-filter-settings {
    padding: 0;
    margin-left: 368px;
    width: calc(100vw - 490px);
    top: 115px;

    .c-drawer__content {
        border: none;

        .el-transfer-panel {
            width: 300px !important;
        }

        .filter-settings-save-btn {
            position: absolute;
            bottom: 16px;
            right: 16px;
        }
    }
}
</style>
