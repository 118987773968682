<template>
    <div :class="['c-filter-list', editMode ? 'edit-mode' : '']">
        <i class="material-icons">filter_list</i>
        <ul class="c-filter-list__list o-list-plain">
            <li
                v-for="(filter, index) in appliedFiltersSorted"
                :key="index"
                class="c-filter-list__item"
            >
                <el-tooltip
                    :openDelay="500"
                    :content="getFilterTooltip(filter)"
                    effect="dark"
                    placement="bottom"
                    popper-class="filter-tooltip"
                >
                    <span :class="['c-tag', filter.irremovable ? 'c-tag--irremovable' : '']">
                        {{ getFilterDisplayText(filter) }}
                        <button v-if="filter.irremovable" class="irremovable-btn">
                            <i class="material-icons">lock</i>
                        </button>
                        <button v-if="canRemoveFilter(filter)" @click.stop="removeFilter(filter)">
                            <i class="material-icons">close</i>
                        </button>
                    </span>
                </el-tooltip>
            </li>
        </ul>
    </div>
</template>

<script>
import moment from "moment";
import { validationMixin } from "vuelidate";
import { filterService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "AppliedFilters",
    mixins: [util, validationMixin],
    props: ["appliedFilters", "editMode", "widget"],
    computed: {
        role() {
            return "ROLE_SUPERADMIN";
        },
        appliedFiltersSorted() {
            if (this.appliedFilters != null && this.appliedFilters.length > 0) {
                const appliedFilters = this.copyObject(this.appliedFilters);
                appliedFilters[0].filterValues.sort(this.objectSortFunc("value"));
                return appliedFilters;
            }
            return [];
        },
    },
    methods: {
        getFilterTooltip(filter) {
            let tooltip = "";
            if (filter.filterTemplate.dateTemplate) {
                tooltip += this.getDateFilterLabel(filter, " ");
            } else {
                $.each(filter.filterValues, (index, filterValue) => {
                    const shouldAnonymize =
                        this.widget?.anonymization?.id &&
                        filter.filterTemplate.column ===
                            this.widget.splitBys[0].filterTemplate.column &&
                        filterValue.value !== this.widget.anonymization.value;
                    tooltip += shouldAnonymize
                        ? `${this.translate("PEER")}, `
                        : `${filterValue.value}, `;
                });
            }
            tooltip = tooltip.replace(/,\s*$/, "");
            return tooltip;
        },
        getFilterDisplayText(filter) {
            let displayText = "";
            if (filter && filter.filterTemplate) {
                displayText = this.translate(filter.filterTemplate.name);
                if (filter.filterValues && filter.filterValues.length > 0) {
                    if (filter.filterTemplate.dateTemplate) {
                        displayText += this.getDateFilterLabel(filter, " ");
                    } else {
                        if (filter.operation) {
                            displayText += ` ${filter.operation} `;
                        }
                        if (filter.filterValues[0].value) {
                            displayText += this.getSplitByFirstDisplayText(
                                filter,
                                filter.filterValues[0].value
                            );
                        }
                        if (filter.filterValues.length > 1) {
                            displayText += " ...";
                        }
                    }
                }
            }
            return displayText;
        },
        getSplitByFirstDisplayText(filter, value) {
            return this.widget?.anonymization?.id &&
                value !== this.widget.anonymization.value &&
                filter.filterTemplate.column === this.widget.splitBys[0].filterTemplate.column
                ? this.translate("PEER")
                : value;
        },
        getDateFilterLabel(filter, label) {
            const momentFormat = "YYYY-MM-DD";
            if (filter.filterValues[0].value) {
                label += `${this.translate("FROM")} ${moment(filter.filterValues[0].value).format(
                    momentFormat
                )} `;
            }
            if (filter.filterValues[1] && filter.filterValues[1].value) {
                label += `${this.translate("TILL")} ${moment(filter.filterValues[1].value).format(
                    momentFormat
                )} `;
            }
            return label;
        },
        canRemoveFilter(filter) {
            return (
                !filter.irremovable || this.role === "ROLE_ADMIN" || this.role === "ROLE_SUPERADMIN"
            );
        },
        removeFilter(filter) {
            filterService.delete(filter.id).then(() => {
                this.$emit("reload");
            });
        },
    },
};
</script>
