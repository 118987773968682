<template>
    <el-dialog
        :visible.sync="visible"
        v-on:opened="popupOpened"
        :before-close="popupClose"
        class="custom-dialog dashboard-library-add"
        :show-close="false"
    >
        <div class="c-modal c-modal--with-tabs" v-loading="loading">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ $t("ADD_DASHBOARD_TO_LIBRARY_TITLE") }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div class="o-form">
                    <fieldset>
                        <ul class="o-form__fields o-list-plain">
                            <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                <input
                                    class="o-form__input o-form__input--full"
                                    type="text"
                                    v-model.trim="$v.form.dashboardName.$model"
                                    autocomplete="off"
                                    maxlength="32"
                                />
                                <label class="o-form__label">{{
                                    $t("DASHBOARD_APP_ADD_DASHBOARD_TO_LIBRARY_DASHBOARD_NAME")
                                }}</label>
                                <span
                                    v-if="
                                        $v.form.dashboardName.$dirty &&
                                        !$v.form.dashboardName.required
                                    "
                                    class="error-msg"
                                    >{{ $t("ENTER_DASHBOARD_NAME") }}</span
                                >
                                <span
                                    v-if="
                                        $v.form.dashboardName.$dirty &&
                                        !$v.form.dashboardName.minLength
                                    "
                                    class="error-msg"
                                    >{{
                                        $t("INVALID_NUMBER_OF_CHARACTERS_FOR_DASHBOARD_NAME")
                                    }}</span
                                >
                            </li>
                            <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                <input
                                    class="o-form__input o-form__input--full"
                                    type="text"
                                    v-model.trim="$v.form.tags.$model"
                                />
                                <label class="o-form__label">{{
                                    $t("ADD_DASHBOARD_TO_LIBRARY_TAGS")
                                }}</label>
                                <span v-if="$v.form.tags.$anyError" class="error-msg">{{
                                    $t(getTagsErrorMsgKey())
                                }}</span>
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click="popupClose" :disabled="loading">
                    {{ $t("CANCEL") }}
                </button>
                <button
                    class="c-btn c-btn--primary"
                    @click="saveToDashboardLibrary"
                    :disabled="loading || $v.$anyError"
                >
                    {{ $t("SUBMIT") }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { dashboardApiClient } from "@/helpers/evaluation/apiclients";
import util from "@/helpers/evaluation/util";

export default {
    name: "DashboardLibraryAdd",
    mixins: [util, validationMixin],
    props: ["visible"],
    data() {
        return {
            loading: false,

            form: {
                dashboardName: "",
                tags: "",
            },
        };
    },
    computed: {
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
    },
    validations() {
        const numOfTagsValid = (tags) => this.removeEmptyStrings(tags.split(" ")).length < 4;
        const lengthOfTagsValid = (tags) => {
            let valid = true;
            const tagList = this.removeEmptyStrings(tags.split(" "));
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < tagList.length; i++) {
                if (tagList[i].length > 64) {
                    valid = false;
                    break;
                }
            }
            return valid;
        };

        return {
            form: {
                dashboardName: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(32),
                },
                tags: {
                    numOfTagsValid,
                    lengthOfTagsValid,
                },
            },
        };
    },
    methods: {
        popupOpened() {
            if (!this.activeDashboardView) {
                this.popupClose();
                return;
            }

            this.setFormData();
        },
        popupClose() {
            this.$emit("dashboardLibraryAddClose");
            this.resetFormData();
        },
        setFormData() {
            let tags = "";
            let dashboardName = "";
            if (this.activeDashboardView.template != null) {
                // eslint-disable-next-line no-plusplus
                for (
                    let i = 0;
                    i < this.activeDashboardView.template.dashboardViewTemplateTags.length;
                    i += 1
                ) {
                    if (i > 0) {
                        tags += " ";
                    }
                    tags += this.activeDashboardView.template.dashboardViewTemplateTags[i].name;
                }
                dashboardName = this.activeDashboardView.template.name;
            } else {
                dashboardName = this.activeDashboardView.editable
                    ? `Custom dashboard - ${this.activeDashboardView.title}`
                    : this.activeDashboardView.title;
            }
            if (dashboardName.length > 32) {
                dashboardName = dashboardName.substr(0, 32);
            }

            this.form.dashboardName = dashboardName;
            this.form.tags = tags;
        },
        saveToDashboardLibrary() {
            if (!this.$v.$anyError) {
                this.loading = true;

                const tags = [];
                const splitTags = this.removeEmptyStrings(this.form.tags.split(" "));
                // eslint-disable-next-line no-plusplus
                for (let i = 0; i < splitTags.length; i++) {
                    tags.push({ name: splitTags[i] });
                }

                const template = {
                    name: this.form.dashboardName,
                    dashboardViewTemplateTags: tags,
                    dashboardViewId: this.activeDashboardView.id,
                };

                dashboardApiClient
                    .post("/dashboard-view-templates/exists", template)
                    .then((response) => {
                        if (response.data.exists) {
                            this.loading = false;
                            this.$confirm(
                                this.translate(
                                    "DASHBOARD_APP_OVERRIDE_DASHBOARD_TEMPLATE_QUESTION",
                                    { number: response.data.numberOfUsers }
                                ),
                                this.translate("OVERRIDE_DASHBOARD_TEMPLATE_TITLE"),
                                {
                                    confirmButtonText: this.translate("OVERRIDE"),
                                    cancelButtonText: this.translate("CANCEL"),
                                    type: "warning",
                                }
                            )
                                .then(() => {
                                    this.saveDashboardTemplate(template);
                                    // eslint-disable-next-line no-undef
                                    done();
                                })
                                .catch(() => {});
                        } else {
                            this.saveDashboardTemplate(template);
                        }
                    })
                    .catch(() => {
                        this.loading = false;
                        this.displayMessage(
                            this.translate(
                                "DASHBOARD_APP_ERROR_CHECKING_DASHBOARD_VIEW_TEMPLATE_EXISTS"
                            ),
                            "error"
                        );
                    });
            }
        },
        saveDashboardTemplate(template) {
            this.loading = true;
            dashboardApiClient
                .post("/dashboard-view-templates", template)
                .then(() => {
                    this.loading = false;
                    this.popupClose();
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_DASHBOARD_VIEW_TEMPLATE_SAVED"),
                        "success"
                    );
                })
                .catch(() => {
                    this.loading = false;
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_SAVING_DASHBOARD_VIEW_TEMPLATE"),
                        "error"
                    );
                });
        },
        resetFormData() {
            this.loading = false;
            this.form.dashboardName = "";
            this.form.tags = "";
            this.$v.$reset();
        },
        getTagsErrorMsgKey() {
            if (!this.$v.form.tags.numOfTagsValid) {
                return "ADD_DASHBOARD_TO_LIBRARY_MAX_TAGS_ALLOWED";
            }
            if (!this.$v.form.tags.lengthOfTagsValid) {
                return "ADD_DASHBOARD_TO_LIBRARY_MAX_TAG_SIZE_ALLOWED";
            }
            return "";
        },
    },
};
</script>
