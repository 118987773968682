<template>
    <div>
        <el-dialog
            :visible.sync="visible"
            width="40%"
            v-on:open="popupOpened"
            :before-close="popupClose"
            class="custom-dialog filter-template-manager"
            :show-close="false"
        >
            <div
                class="c-modal c-modal--with-tabs"
                v-loading="loading"
                :element-loading-text="loadingText"
            >
                <div class="c-modal__head">
                    <div class="c-modal__title">
                        <h1>{{ $t("DASHBOARD_APP_CREATE_MANAGE_FILTER_TEMPLATES") }}</h1>
                        <nav class="o-nav c-tabs">
                            <ul class="o-nav__list c-tabs__list o-list-plain">
                                <li class="c-tabs__item">
                                    <a
                                        :class="[
                                            'o-nav__link',
                                            'c-tabs__link',
                                            showCreateTab ? 'is-active' : '',
                                        ]"
                                        @click="showCreateTab = true"
                                        >{{ $t("DASHBOARD_APP_FILTER_TEMPLATES_CREATE_TAB") }}</a
                                    >
                                </li>
                                <li class="c-tabs__item">
                                    <a
                                        :class="[
                                            'o-nav__link',
                                            'c-tabs__link',
                                            !showCreateTab ? 'is-active' : '',
                                        ]"
                                        @click="showCreateTab = false"
                                        >{{ $t("DASHBOARD_APP_FILTER_TEMPLATES_MANAGE_TAB") }}</a
                                    >
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="c-modal__actions">
                        <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                            <i class="material-icons">close</i>
                        </button>
                    </div>
                </div>
                <div class="c-modal__content">
                    <div v-show="showCreateTab">
                        <div class="o-form">
                            <fieldset>
                                <legend>
                                    {{ $t("DASHBOARD_APP_FILTER_TEMPLATES_CREATE_TITLE") }}
                                </legend>
                                <ul class="o-form__fields o-list-plain">
                                    <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                        <input
                                            v-model.trim="filterTemplateName"
                                            id="filter-template-name"
                                            name="filter-template-name"
                                            class="o-form__input o-form__input--full"
                                            type="text"
                                            :placeholder="$t('FILTER_TEMPLATE_NAME')"
                                        />
                                        <label class="o-form__label" for="filter-template-name">{{
                                            $t("FILTER_TEMPLATE_NAME")
                                        }}</label>
                                    </li>
                                </ul>
                            </fieldset>
                            <fieldset>
                                <legend>{{ $t("FILTER_BY") }}</legend>
                                <ul class="o-form__fields o-list-plain">
                                    <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                        <div class="o-form__select o-form__select--full">
                                            <select
                                                v-model="filterCategory"
                                                @change="filterCategorySelected"
                                            >
                                                <option :value="null" disabled>
                                                    {{ $t("CATEGORY") }}
                                                </option>
                                                <option
                                                    v-for="(category, i) in filterCategories"
                                                    :key="i"
                                                    :value="category"
                                                >
                                                    {{ category.name }}
                                                </option>
                                            </select>
                                        </div>
                                        <label class="o-form__label" for="filter name">{{
                                            $t("CATEGORY")
                                        }}</label>
                                    </li>
                                    <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                        <div class="o-form__select o-form__select--full">
                                            <select
                                                v-model="filterSubCategory"
                                                :disabled="filterCategory == null"
                                            >
                                                <option :value="null" disabled>
                                                    {{ $t("SUB_CATEGORY") }}
                                                </option>
                                                <option
                                                    v-for="(subCategory, i) in filterSubCategories"
                                                    :key="i"
                                                    :value="subCategory"
                                                >
                                                    {{ subCategory }}
                                                </option>
                                            </select>
                                        </div>
                                        <label class="o-form__label" for="filter name">{{
                                            $t("SUB_CATEGORY")
                                        }}</label>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
                    </div>
                    <div v-show="!showCreateTab">
                        <h2>{{ $t("SAVED_FILTERS") }}</h2>
                        <ul class="c-action-list o-list-plain">
                            <li
                                v-for="(filterTemplate, i) in filterTemplates"
                                :key="i"
                                class="c-action-list__item"
                            >
                                <div class="c-action-list__content">
                                    <p>{{ filterTemplate.name }}</p>
                                    <span
                                        >{{ getSelectedCategory(filterTemplate) }} -
                                        {{ filterTemplate.column }}</span
                                    >
                                </div>
                                <div class="c-action-list__actions">
                                    <button
                                        class="c-btn c-btn--clean c-btn--gray"
                                        @click="openEditDialog(filterTemplate)"
                                    >
                                        <i class="material-icons">edit</i>
                                    </button>
                                    <button
                                        class="c-btn c-btn--clean c-btn--gray"
                                        @click="deleteFilterTemplate(filterTemplate)"
                                    >
                                        <i class="material-icons">delete</i>
                                    </button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="c-modal__footer">
                    <button class="c-btn c-btn--outline" @click="popupClose">
                        {{ $t("CLOSE") }}
                    </button>
                    <button
                        class="c-btn c-btn--primary"
                        v-if="showCreateTab"
                        @click="saveFilterTemplate"
                        :disabled="!templateFormValid"
                    >
                        {{ $t("SAVE_FILTER") }}
                    </button>
                </div>
            </div>
        </el-dialog>

        <confirm-dialog
            :visible="confirmDialogVisible"
            @close="closeConfirmDialog"
            @confirmed="filterTemplateDeletionConfirmed"
            title="DASHBOARD_APP_MODAL_DELETE_FILTER_TEMPLATE_TITLE"
            message="DASHBOARD_APP_MODAL_DELETE_FILTER_TEMPLATE_MESSAGE"
            :messageParams="{
                itemTitle: filterTemplateToDelete != null ? filterTemplateToDelete.name : '',
            }"
            inputMessage="MODAL_DELETE_FILTER_TEMPLATE_CONFIRM_MSG"
            cancelBtn="CANCEL"
            confirmBtn="DELETE"
            validationText="MODAL_DELETE_FILTER_TEMPLATE_CONFIRM"
        />
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { filterTemplateService } from "@/services/Evaluation";
import ConfirmDialog from "@/components/Evaluation/confirm-dialog/src/ConfirmDialog.vue";
import util from "@/helpers/evaluation/util";

export default {
    name: "FilterTemplateManager",
    mixins: [util, validationMixin],
    components: { ConfirmDialog },
    props: ["visible"],
    data() {
        return {
            loading: false,
            showCreateTab: true,

            filterCategories: [],
            filterSubCategories: [],

            filterTemplateName: "",
            filterCategory: null,
            filterSubCategory: null,

            filterTemplateToDelete: null,
            confirmDialogVisible: false,
        };
    },
    computed: {
        filterTemplates() {
            return this.$store.state.DashboardStore.filterTemplates;
        },
        filterTemplatesLoaded() {
            return this.$store.state.DashboardStore.filterTemplatesLoaded;
        },
        templateFormValid() {
            return (
                this.filterTemplateName !== "" &&
                this.filterCategory != null &&
                this.filterSubCategory != null &&
                this.filterSubCategory !== ""
            );
        },
        loadingText() {
            return !this.filterTemplatesLoaded
                ? this.translate("DASHBOARD_APP_LOADING_FILTER_TEMPLATES")
                : "";
        },
    },
    methods: {
        popupOpened() {
            if (!this.filterTemplatesLoaded) {
                this.loading = true;
            }
            filterTemplateService
                .getFilterCategories()
                .then((response) => {
                    this.filterCategories = response.data;
                })
                .catch(() => {
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_GETTING_FILTER_CATEGORIES"),
                        "error"
                    );
                });
        },
        popupClose() {
            this.$emit("close");
            this.resetFormData();
        },
        resetFormData() {
            this.loading = false;
            this.showCreateTab = true;
            this.filterTemplateName = "";
            this.filterCategory = null;
            this.filterSubCategory = null;
        },
        filterCategorySelected() {
            this.filterSubCategories = this.filterCategory.subcategories;
            this.filterSubCategory = null;
        },
        saveFilterTemplate() {
            if (this.templateFormValid) {
                const filterTemplate = {
                    name: this.filterTemplateName,
                    table: this.filterCategory.label,
                    column: this.filterSubCategory,
                    visibleOnSplitByOnly: this.filterCategory.label === "dimDate",
                    dateTemplate: false,
                    reactionDate: false,
                };
                this.loading = true;
                filterTemplateService
                    .save(filterTemplate)
                    .then(() => {
                        this.loading = false;
                        this.resetFormData();
                        this.displayMessage(
                            this.translate("DASHBOARD_APP_FILTER_TEMPLATE_SAVED"),
                            "success"
                        );
                    })
                    .catch(() => {
                        this.loading = false;
                        this.displayMessage(
                            this.translate("DASHBOARD_APP_ERROR_SAVING_FILTER_TEMPLATE"),
                            "error"
                        );
                    });
            }
        },
        getSelectedCategory(filterTemplate) {
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < this.filterCategories.length; i++) {
                if (this.filterCategories[i].label === filterTemplate.table) {
                    return this.filterCategories[i].name;
                }
            }
        },
        openEditDialog(filterTemplate) {
            this.$prompt(this.translate("FILTER_TEMPLATE_NAME"), "Edit", {
                confirmButtonText: this.translate("SAVE"),
                cancelButtonText: this.translate("CANCEL"),
                inputValue: filterTemplate.name,
                inputValidator(inputValue) {
                    return inputValue && inputValue.trim().length > 0;
                },
                inputErrorMessage: this.translate("DASHBOARD_APP_FILTER_TEMPLATE_NAME_REQUIRED"),
            }).then(({ value }) => {
                if (filterTemplate.name !== value) {
                    this.loading = true;
                    filterTemplateService
                        .rename(filterTemplate.id, value)
                        .then(() => {
                            this.loading = false;
                            this.$emit("renamed", filterTemplate);
                            this.displayMessage(
                                this.translate("DASHBOARD_APP_FILTER_TEMPLATE_SAVED"),
                                "success"
                            );
                        })
                        .catch(() => {
                            this.loading = false;
                            this.displayMessage(
                                this.translate("DASHBOARD_APP_ERROR_SAVING_FILTER_TEMPLATE"),
                                "error"
                            );
                        });
                }
            });
        },
        deleteFilterTemplate(filterTemplate) {
            this.filterTemplateToDelete = filterTemplate;
            this.openConfirmDialog();
        },
        openConfirmDialog() {
            this.confirmDialogVisible = true;
        },
        closeConfirmDialog() {
            this.confirmDialogVisible = false;
        },
        filterTemplateDeletionConfirmed() {
            this.closeConfirmDialog();

            if (this.filterTemplateToDelete == null) {
                return;
            }

            this.loading = true;

            filterTemplateService
                .delete(this.filterTemplateToDelete.id)
                .then(() => {
                    this.loading = false;
                    this.$emit("deleted", this.filterTemplateToDelete);
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_FILTER_TEMPLATE_DELETED"),
                        "success"
                    );
                })
                .catch(() => {
                    this.loading = false;
                    this.displayMessage(
                        this.translate("DASHBOARD_APP_ERROR_DELETING_FILTER_TEMPLATE"),
                        "error"
                    );
                });
        },
    },
    watch: {
        filterTemplatesLoaded(loaded) {
            if (loaded) {
                this.loading = false;
            }
        },
    },
};
</script>
