<template>
    <div class="template-value-filter">
        <legend>{{ $t("FILTER_TEMPLATE_NAME") }}</legend>
        <ol class="o-form__list o-list-plain">
            <li class="o-form__item o-form__item--sm">
                <div class="o-form__select o-form__select--full">
                    <select v-model="filterTemplate" @change="templateSelected">
                        <option :value="null" disabled>{{ filterTemplatesLabel }}</option>
                        <option
                            v-for="(template, index) in filterTemplates"
                            :key="index"
                            v-show="template.values && template.values.length > 0"
                            :value="template"
                        >
                            {{ template.name }}
                        </option>
                    </select>
                </div>
            </li>
            <li class="o-form__item o-form__item--sm">
                <div class="o-form__select o-form__select--full">
                    <select v-model="operation">
                        <option v-show="operation === ''" disabled selected value="">
                            {{ $t("CHOOSE_OPERATION") }}
                        </option>
                        <option v-for="(op, i) in operations" :key="i" :value="op">{{ op }}</option>
                    </select>
                </div>
            </li>
            <li class="o-form__item o-form__item--sm">
                <div v-click-outside="closeFilterValueDropdown" class="c-dropdown">
                    <div class="o-form__select o-form__select--full o-form__select--dropdown">
                        <button
                            ref="filterValueDropdown"
                            :disabled="filterTemplate == null"
                            class="c-btn c-btn--block"
                            @click="toggleDropdown"
                        >
                            <div class="dropdown-btn-label">{{ selectedValuesLabel }}</div>
                        </button>
                        <div
                            class="c-dropdown__container c-dropdown__container--full c-dropdown__container--search"
                        >
                            <div class="c-dropdown__content c-dropdown__content--search">
                                <div class="c-search">
                                    <input
                                        v-model="searchText"
                                        :placeholder="$t('SEARCH_FILTER')"
                                        class="c-search__input"
                                        type="text"
                                    /><i class="material-icons">search</i>
                                </div>
                                <ul class="c-dropdown__group">
                                    <li>
                                        <div class="c-checkbox">
                                            <Checkbox
                                                v-model="allSelected"
                                                :label="$t('SELECT_ALL')"
                                                @click="$nextTick(toggleAll)"
                                            />
                                        </div>
                                    </li>
                                </ul>
                                <ul class="c-dropdown__group">
                                    <li
                                        v-for="(templateValue, i) in filteredTemplateValues"
                                        :key="i"
                                    >
                                        <Checkbox
                                            class="filter-options"
                                            v-model="templateValue.selected"
                                            :label="templateValue.value.toString()"
                                            @click="toggle(i)"
                                        />
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </li>
            <li v-if="role === 'ROLE_ADMIN' || role === 'ROLE_SUPERADMIN'" class="o-form__item">
                <div class="c-checkbox">
                    <input
                        :id="getInputId('filter-irremovable')"
                        v-model="irremovable"
                        type="checkbox"
                    />
                    <label
                        :for="getInputId('filter-irremovable')"
                        class="o-form__label o-form__label--checkbox"
                        >{{ $t("IRREMOVABLE") }}</label
                    >
                </div>
            </li>
            <li class="o-form__item o-form__item--sm">
                <button
                    :disabled="selectedValues.length === 0"
                    class="c-btn c-btn--primary c-btn--block apply-filter-btn"
                    @click="createFilter"
                >
                    {{ $t("APPLY_FILTER") }}
                </button>
            </li>
        </ol>
    </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { Checkbox } from "@feedbackcompany/feedback-company-vue-components";
import { filterService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "TemplateValueFilter",
    mixins: [util, validationMixin],
    components: {
        Checkbox,
    },
    props: ["visible", "widgetId", "dashboardViewId"],
    data() {
        return {
            filterTemplate: null,
            operation: "=",
            templateValues: [],
            searchText: "",
            allSelected: false,
            irremovable: false,

            operations: ["=", "!=", "<", ">", "<=", ">="],
        };
    },
    computed: {
        role() {
            return "ROLE_SUPERADMIN";
        },
        activeDashboard() {
            return this.$store.state.DashboardStore.activeDashboard;
        },
        activeDashboardView() {
            return this.$store.state.DashboardStore.activeDashboardView;
        },
        filterTemplates() {
            return this.$store.state.DashboardStore.filterTemplates.filter(
                (filterTemplate) =>
                    // Special case: communications dashboard and recipients table
                    // eslint-disable-next-line implicit-arrow-linebreak
                    !(
                        (this.activeDashboard.communicationsDashboard &&
                            filterTemplate.table === "dimRecipient") ||
                        filterTemplate.visibleOnSplitByOnly ||
                        !filterTemplate.values ||
                        !(
                            this.activeDashboardView.allowedAllFilterTemplates ||
                            (this.activeDashboardView.allowedFilterTemplateIds &&
                                this.activeDashboardView.allowedFilterTemplateIds.includes(
                                    filterTemplate.id
                                ))
                        )
                    )
            );
        },
        filterTemplatesLoaded() {
            return this.$store.state.DashboardStore.filterTemplatesLoaded;
        },
        filterTemplatesLabel() {
            if (this.filterTemplatesLoaded) {
                return this.translate("CHOOSE_FILTER");
            }
            return this.translate("DASHBOARD_APP_LOADING_FILTER_TEMPLATES");
        },
        filteredTemplateValues() {
            return this.templateValues.filter((templateValue) => {
                if (this.searchText == null || this.searchText.trim() === "") {
                    return true;
                }
                return (
                    templateValue.value
                        .toString()
                        .toLowerCase()
                        .indexOf(this.searchText.toLowerCase()) > -1
                );
            });
        },
        selectedValues() {
            const selectedValues = [];
            $.each(this.templateValues, (index, templateValue) => {
                if (templateValue.selected) {
                    selectedValues.push(templateValue.value);
                }
            });
            return selectedValues;
        },
        selectedValuesLabel() {
            const numOfSelectedValues = this.selectedValues.length;
            let messageParam = numOfSelectedValues;
            if (numOfSelectedValues === 1) {
                return this.selectedValues[0];
            }
            if (numOfSelectedValues === 0) {
                messageParam = this.translate("NONE");
            } else if (numOfSelectedValues === this.templateValues.length) {
                messageParam = this.translate("ALL");
            }
            return this.translate("DASHBOARD_APP_NUM_OF_FILTER_VALUES_SELECTED", {
                num: messageParam,
            });
        },
    },
    methods: {
        templateSelected() {
            this.allSelected = false;
            this.searchText = "";
            this.populateTemplateValues();
        },
        populateTemplateValues() {
            const values = [];
            if (this.filterTemplate != null) {
                $.each(this.filterTemplate.values, (index, value) => {
                    values.push({
                        index,
                        value: value[0],
                        selected: false,
                    });
                });
                values.sort(this.objectSortFunc("value"));
            }
            this.templateValues = values;
        },
        toggle(index) {
            if (!this.filteredTemplateValues[index].selected === false) {
                this.allSelected = false;
                this.filteredTemplateValues[index].selected = false;
            } else {
                this.filteredTemplateValues[index].selected = true;
                let allFilteredTemplateValuesSelected = true;
                this.filteredTemplateValues.forEach((filteredTemplateValue) => {
                    if (filteredTemplateValue.selected === false)
                        allFilteredTemplateValuesSelected = false;
                });
                this.allSelected = allFilteredTemplateValuesSelected;
            }
        },
        toggleAll() {
            this.$set(
                this.templateValues,
                this.templateValues.map((templateValue) => {
                    templateValue.selected = this.allSelected;
                    return templateValue;
                })
            );
        },
        toggleValue(templateValue) {
            templateValue.selected = !templateValue.selected;
            if (
                this.selectedValues.length > 0 &&
                this.selectedValues.length === this.templateValues.length
            ) {
                this.allSelected = true;
            } else {
                this.allSelected = false;
            }
        },
        createFilter() {
            if (this.selectedValues.length > 0) {
                const filter = {};
                filter.filterTemplateId = this.filterTemplate.id;
                if (this.widgetId != null) {
                    filter.widgetId = this.widgetId;
                } else if (this.dashboardViewId != null) {
                    filter.dashboardViewId = this.dashboardViewId;
                }
                filter.operation = this.operation;
                filter.irremovable = this.irremovable;
                filter.filterValues = this.selectedValues;
                filter.dateFilter = false;
                filterService.save(filter).then(() => {
                    this.$emit("applyFilter");
                    this.reload();
                });
            }
        },
        reload() {
            this.$emit("reload");
            this.resetForm();
        },
        getInputId(suffix) {
            if (this.widgetId != null) {
                return `widget-${this.widgetId}-${suffix}`;
            }
            if (this.dashboardViewId != null) {
                return `dashboard-${this.dashboardViewId}-${suffix}`;
            }
            return suffix;
        },
        resetForm() {
            this.filterTemplate = null;
            this.operation = "=";
            this.templateValues = [];
            this.searchText = "";
            this.allSelected = false;
            this.irremovable = false;
        },
        closeFilterValueDropdown(event) {
            this.closeDropdown(event, this.$refs.filterValueDropdown);
        },
    },
    watch: {
        visible() {
            this.resetForm();
        },
    },
};
</script>

<style lang="scss">
.filter-options {
    & > .checkbox {
        align-items: center;
        padding: 4px 0;
    }
}
</style>
