var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{staticClass:"custom-dialog dashboard-library-add",attrs:{"visible":_vm.visible,"before-close":_vm.popupClose,"show-close":false},on:{"update:visible":function($event){_vm.visible=$event},"opened":_vm.popupOpened}},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"c-modal c-modal--with-tabs"},[_c('div',{staticClass:"c-modal__head"},[_c('div',{staticClass:"c-modal__title"},[_c('h1',[_vm._v(_vm._s(_vm.$t("ADD_DASHBOARD_TO_LIBRARY_TITLE")))])]),_c('div',{staticClass:"c-modal__actions"},[_c('button',{staticClass:"c-btn c-btn--clean c-btn--gray",on:{"click":_vm.popupClose}},[_c('i',{staticClass:"material-icons"},[_vm._v("close")])])])]),_c('div',{staticClass:"c-modal__content"},[_c('div',{staticClass:"o-form"},[_c('fieldset',[_c('ul',{staticClass:"o-form__fields o-list-plain"},[_c('li',{staticClass:"o-form__item o-form__item--sm o-form__item--stacked"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.dashboardName.$model),expression:"$v.form.dashboardName.$model",modifiers:{"trim":true}}],staticClass:"o-form__input o-form__input--full",attrs:{"type":"text","autocomplete":"off","maxlength":"32"},domProps:{"value":(_vm.$v.form.dashboardName.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.dashboardName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"o-form__label"},[_vm._v(_vm._s(_vm.$t("DASHBOARD_APP_ADD_DASHBOARD_TO_LIBRARY_DASHBOARD_NAME")))]),(
                                    _vm.$v.form.dashboardName.$dirty &&
                                    !_vm.$v.form.dashboardName.required
                                )?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.$t("ENTER_DASHBOARD_NAME")))]):_vm._e(),(
                                    _vm.$v.form.dashboardName.$dirty &&
                                    !_vm.$v.form.dashboardName.minLength
                                )?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.$t("INVALID_NUMBER_OF_CHARACTERS_FOR_DASHBOARD_NAME")))]):_vm._e()]),_c('li',{staticClass:"o-form__item o-form__item--sm o-form__item--stacked"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.form.tags.$model),expression:"$v.form.tags.$model",modifiers:{"trim":true}}],staticClass:"o-form__input o-form__input--full",attrs:{"type":"text"},domProps:{"value":(_vm.$v.form.tags.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.form.tags, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),_c('label',{staticClass:"o-form__label"},[_vm._v(_vm._s(_vm.$t("ADD_DASHBOARD_TO_LIBRARY_TAGS")))]),(_vm.$v.form.tags.$anyError)?_c('span',{staticClass:"error-msg"},[_vm._v(_vm._s(_vm.$t(_vm.getTagsErrorMsgKey())))]):_vm._e()])])])])]),_c('div',{staticClass:"c-modal__footer"},[_c('button',{staticClass:"c-btn c-btn--outline",attrs:{"disabled":_vm.loading},on:{"click":_vm.popupClose}},[_vm._v(" "+_vm._s(_vm.$t("CANCEL"))+" ")]),_c('button',{staticClass:"c-btn c-btn--primary",attrs:{"disabled":_vm.loading || _vm.$v.$anyError},on:{"click":_vm.saveToDashboardLibrary}},[_vm._v(" "+_vm._s(_vm.$t("SUBMIT"))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }