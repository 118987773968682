<template>
    <el-dialog
        :before-close="popupClose"
        :show-close="false"
        :visible.sync="visible"
        class="custom-dialog widget-edit"
        v-on:open="popupOpened"
    >
        <div v-if="widgetData != null" v-loading="loading" class="c-modal c-modal--with-tabs">
            <div class="c-modal__head">
                <div class="c-modal__title">
                    <h1>{{ widgetData.config.widgetTitle }}</h1>
                </div>
                <div class="c-modal__actions">
                    <button class="c-btn c-btn--clean c-btn--gray" @click="popupClose">
                        <i class="material-icons">close</i>
                    </button>
                </div>
            </div>
            <div class="c-modal__content">
                <div class="o-form" label-width="80px">
                    <fieldset>
                        <ul class="o-form__fields o-list-plain">
                            <li class="o-form__item o-form__item--sm o-form__item--stacked">
                                <input
                                    v-model.trim="$v.widgetForm.title.$model"
                                    autocomplete="off"
                                    class="o-form__input o-form__input--full"
                                    maxlength="128"
                                    type="text"
                                />
                                <label class="o-form__label">{{ $t("TITLE") }}</label>
                                <span v-if="!$v.widgetForm.title.required" class="error-msg">{{
                                    $t("ENTER_TITLE")
                                }}</span>
                            </li>
                            <li
                                v-if="
                                    widgetData.widget.widgetTemplate.widgetTemplateType !==
                                        'GAUGE' &&
                                    widgetData.widget.widgetTemplate.widgetTemplateType !==
                                        'MOOD_MONITOR_GAUGE' &&
                                    widgetData.widget.widgetTemplate.widgetTemplateType !== 'TABLE'
                                "
                                class="o-form__item o-form__item--sm o-form__item--stacked"
                            >
                                <span class="desc"
                                    >Only numbers allowed (negatives, positives and decimals)</span
                                >
                                <input
                                    v-model.number="$v.widgetForm.target.$model"
                                    class="o-form__input o-form__input--full"
                                    name="widgetTarget"
                                    type="number"
                                />
                                <label class="o-form__label">{{ $t("TARGET") }}</label>
                                <span v-if="!$v.widgetForm.target.decimal" class="error-msg">{{
                                    $t("TARGET_VALUE_INPUT_ERROR")
                                }}</span>
                            </li>
                            <li
                                v-show="widgetData.widget.widgetTemplate.benchmark"
                                class="o-form__item o-form__item--stacked"
                            >
                                <div class="c-checkbox">
                                    <input
                                        :id="'widget-edit-benchmark-' + widgetData.id"
                                        v-model="widgetForm.skipBenchmarkFilters"
                                        type="checkbox"
                                    />
                                    <label
                                        :for="'widget-edit-benchmark-' + widgetData.id"
                                        class="o-form__label o-form__label--checkbox"
                                        >{{ $t("SKIP_BENCHMARK_FILTERS") }}</label
                                    >
                                </div>
                            </li>
                        </ul>
                    </fieldset>
                </div>
            </div>
            <div class="c-modal__footer">
                <button class="c-btn c-btn--outline" @click="popupClose">{{ $t("CANCEL") }}</button>
                <button
                    :disabled="loading || $v.$anyError"
                    class="c-btn c-btn--primary"
                    @click="saveWidget"
                >
                    {{ $t("SAVE") }}
                </button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import { decimal, maxLength, required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import { widgetService } from "@/services/Evaluation";
import util from "@/helpers/evaluation/util";

export default {
    name: "WidgetEdit",
    mixins: [util, validationMixin],
    props: ["visible", "widgetData"],
    data() {
        return {
            loading: false,

            widgetForm: {
                title: "",
                target: "",
                skipBenchmarkFilters: false,
            },
        };
    },
    validations: {
        widgetForm: {
            title: {
                required,
                maxLength: maxLength(128),
            },
            target: {
                decimal,
            },
        },
    },
    methods: {
        popupOpened() {
            this.widgetForm.title = this.widgetData.config.widgetTitle;
            this.widgetForm.target =
                this.widgetData.config.widgetTarget != null
                    ? this.widgetData.config.widgetTarget
                    : "";
            // Note: skipBenchmarkFilters could be undefined (it was added later)
            this.widgetForm.skipBenchmarkFilters = this.widgetData.config.skipBenchmarkFilters
                ? this.widgetData.config.skipBenchmarkFilters
                : false;
        },
        popupClose() {
            this.$emit("widgetEditClose");
            this.resetFormData();
        },
        saveWidget() {
            if (!this.$v.$anyError) {
                if (
                    this.widgetData.config.widgetTitle !== this.widgetForm.title ||
                    this.widgetData.config.widgetTarget !==
                        (this.widgetForm.target !== "" ? this.widgetForm.target : null) ||
                    (this.widgetData.config.skipBenchmarkFilters !==
                        this.widgetForm.skipBenchmarkFilters &&
                        (this.widgetData.config.skipBenchmarkFilters ||
                            this.widgetForm.skipBenchmarkFilters))
                ) {
                    this.loading = true;
                    widgetService
                        .getWidget(this.widgetData.config.widgetId, false)
                        .then((widget) => {
                            const widgetToUpdate = { ...widget };
                            widgetToUpdate.title = this.widgetForm.title;
                            widgetToUpdate.target =
                                this.widgetForm.target !== "" ? this.widgetForm.target : null;
                            widgetToUpdate.skipBenchmarkFilters =
                                this.widgetForm.skipBenchmarkFilters;
                            widgetService
                                .updateWidget(widgetToUpdate)
                                .then((updatedWidget) => {
                                    this.loading = false;
                                    this.$emit("updateWidgetData", {
                                        widgetType: "unicornDefaultWidget",
                                        widgetId: updatedWidget.id,
                                        title: updatedWidget.title,
                                        target: updatedWidget.target,
                                        skipBenchmarkFilters: updatedWidget.skipBenchmarkFilters,
                                    });
                                    this.popupClose();
                                })
                                .catch(() => {
                                    this.loading = false;
                                    this.displayMessage(
                                        this.translate("TARGET_VALUE_INPUT_ERROR"),
                                        "error"
                                    );
                                });
                        })
                        .catch(() => {
                            this.loading = false;
                            this.popupClose();
                        });
                } else {
                    this.popupClose();
                }
            }
        },
        resetFormData() {
            this.loading = false;
            this.widgetForm.title = "";
            this.widgetForm.target = "";
            this.widgetForm.skipBenchmarkFilters = false;
        },
    },
};
</script>

<style scoped lang="scss">
.o-form__item {
    > .desc {
        font-size: 0.75rem;
    }
}
</style>
